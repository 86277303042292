import * as React from "react";
import { Helmet } from "react-helmet";

import {
  CallToAction,
  Layout,
  LoveSection,
  MainHeader,
  ServicesMenuSection,
  WhySection,
} from "../components/containers";
import SEO from "../components/marketing/Seo";
import { Home_Meta } from "../data/MetaData";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="elpvus1bpbpfi1dl92l35vh8pbq3zg"
        />
      </Helmet>
      <SEO title={Home_Meta.title} description={Home_Meta.description} />
      <MainHeader />
      <ServicesMenuSection />
      <LoveSection />
      <CallToAction />
      <WhySection />
    </Layout>
  );
};

export default IndexPage;
